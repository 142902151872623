.btn {
    background-color: #fff;
    border: 2px solid #891F2A;
    color: #891F2A;
    padding: 0.5em 1em;
    font-weight: bolder;
    text-decoration: none;
    transition: 0.5s;
    border-radius: 1.5px;
    display: inline-flex;
    align-items: center; /* Garante o alinhamento do ícone e texto */
    gap: 0.5em; /* Espaço entre o ícone e o texto */
  }
  
  .btn:hover {
    color: #fff;
    background-color: #891F2A;
    border: 2px solid #891F2A;
  }
  
  .icon {
    font-size: 1em; /* Tamanho do ícone */
    margin-right: 10px;
  }
  
  .text {
    font-size: 1em;
  }
  