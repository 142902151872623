.about_us_section {
  position: relative;
  padding: 2em 1em 4em 1em; /* Espaçamento superior e inferior restaurado */
  background-size: cover;
  background-position: center;
  color: #fff;
  text-align: center;
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(137, 31, 42, 0.85); /* Sobreposição com cor */
  z-index: 1;
}

.about_container {
  position: relative;
  z-index: 2; /* Coloca o conteúdo acima da sobreposição */
}

.about_us_section h2 {
  font-size: 2.5em;
  margin-bottom: 0.2em;
  position: relative;
  display: inline-block;
  width: 80%; /* Permite que o traço ocupe toda a largura */
}

.about_us_section h2::after {
  content: "";
  display: block;
  width: 100%; /* Traço pegando a largura total */
  height: 2px;
  background: #fff;
  margin: 0.2em 0;
}

.subheading {
  font-size: 1.4em;
  margin-bottom: 1em; /* Reduzi o espaçamento para aproximar dos textos */
}

.about_content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 0 auto;
  gap: 1.5em; /* Ajustado para espaçamento entre textos */
}

.text {
  flex: 1;
  font-size: 1.2em;
  min-width: 250px;
  text-align: left;
  margin: 0 2em;
}

.about_image {
  width: 300px;
  border-radius: 10px;
  margin: 0; /* Removido o espaçamento para aproximar */
}

.image_container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.button_container {
  margin-top: 1em;
}

.button {
  display: inline-block;
  padding: 0.8em 1.5em;
  background-color: #fff;
  color: #891F2A;
  text-decoration: none;
  border-radius: 5px;
  font-weight: bold;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.button:hover {
  background-color: #ccc;
  color: #891F2A;
}

/* Responsividade */
@media (max-width: 1024px) {
  .about_content {
    flex-direction: column;
    align-items: center;
    gap: 1em; /* Ajustado para reduzir espaçamento entre os elementos */
  }

  .text {
    font-size: 1.1em;
    text-align: center;
    margin: 1em 0;
  }

  .about_image {
    width: 80%;
    max-width: 300px;
  }
}

@media (max-width: 768px) {
  .about_us_section h2 {
    font-size: 2em;
  }

  .subheading {
    font-size: 1.2em;
    margin-bottom: 1em; /* Ajustado para menor espaçamento */
  }

  .text {
    font-size: 1em;
    margin: 1em 0;
  }
}
