.backButton {
  display: inline-block;
  margin-bottom: 1.5rem;
  text-decoration: none;
  color: #891F2A;
  font-weight: bold;
  font-size: 1.2em;
  transition: color 0.3s ease;
}

.backButton:hover {
  color: #d13a3f;
}

.projectDetails {
  padding: 2rem;
  background-color: #fff;
  text-align: center;
}

.title {
  font-size: 2.5em;
  color: #333;
  font-weight: 600;
  margin-bottom: 0.5rem;
}

.title_underline {
  width: 90%;
  max-width: 500px;
  height: 4px;
  background-color: #891F2A;
  margin: 0.5em auto 1.5em auto;
}

.projectImage {
  width: 40%;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 1.5em;
  transition: transform 0.3s ease;
}

.projectImage:hover {
  transform: scale(1.05);
}

.description {
  font-size: 1.2em;
  margin-bottom: 2rem;
  color: #555;
}

.membersTitle {
  font-size: 1.5em;
  color: #333;
  margin-bottom: 1rem;
}

@media (max-width: 767px) {
  .projectDetails {
    padding: 1.5rem;
  }

  .title {
    font-size: 2em;
  }

  .title_underline {
    width: 90%;
    height: 2px;
  }

  .projectImage {
    width: 70%;
  }

  .description {
    font-size: 1em;
  }

  .membersTitle {
    font-size: 1.3em;
  }
}

@media (max-width: 480px) {
  .projectDetails {
    padding: 1rem;
  }

  .title {
    font-size: 1.8em;
  }

  .title_underline {
    width: 80%;
  }

  .projectImage {
    width: 100%;
  }

  .description {
    font-size: 1em;
  }

  .membersTitle {
    font-size: 1.2em;
  }
}
