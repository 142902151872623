.success_services_section {
  padding: 2rem;
  text-align: center;
  max-width: 1200px;
  margin: 0 auto;
}

.success_services_section h1 {
  font-size: 2.5em;
  color: #333;
  margin-bottom: 0.5rem;
  font-weight: 600;
}

.success_services_section h2 {
  font-size: 1.8em;
  color: #444;
  margin-bottom: 1.5rem;
}

.success_services_section p {
  margin-bottom: 2rem;
  font-size: 1.2em;
  color: #666;
}

.title_underline {
  width: 90%;
  max-width: 500px;
  height: 4px;
  background-color: #891F2A;
  margin: 0.5em auto 1.5em auto;
}

.image_container {
  width: 100%;
  margin: 0 auto;
  position: relative;
}

.slick-slide {
  transition: transform 0.5s ease-in-out;
}

.slick-center img {
  transform: scale(1.2); /* Destaque na imagem central */
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
  border-radius: 12px;
}

.slick-prev, .slick-next {
  width: 40px;
  height: 40px;
  background-color: rgba(137, 31, 42, 0.8); /* Botões na cor vermelha escura */
  border-radius: 50%;
  cursor: pointer;
  z-index: 10;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.slick-prev {
  left: -15px; /* Reposiciona o botão anterior */
}

.slick-next {
  right: -15px; /* Reposiciona o botão próximo */
}

.slick-prev::before, .slick-next::before {
  display: none;
}

.custom_next_arrow span, .custom_prev_arrow span {
  font-size: 1.8em;
  color: #fff;
}

.about_image {
  width: 100%;
  height: auto;
  max-height: 400px;
  object-fit: cover;
  border-radius: 12px;
  transition: transform 0.3s ease-in-out;
}

.about_content {
  margin-top: 2rem;
  max-width: 800px;
  margin: 2rem auto;
  text-align: left;
}

.about_content h2 {
  font-size: 2.5em;
  color: #891F2A;
  margin-bottom: 1rem;
}

.about_content p {
  font-size: 1.2em;
  line-height: 1.6;
  color: #666;
}

/* Responsivo para Tablets */
@media (max-width: 1024px) {
  .about_image {
    max-height: 350px;
  }

  .slick-prev, .slick-next {
    top: 50%;
  }
}

/* Responsivo para Celulares */
@media (max-width: 768px) {
  .success_services_section h1 {
    font-size: 2em;
  }

  .title_underline {
    height: 3px;
    margin: 0.3em auto 1em;
  }

  .about_image {
    max-height: 300px; /* Proporção reduzida para dispositivos móveis */
  }

  .slick-prev, .slick-next {
    width: 30px;
    height: 30px;
    top: calc(50% - 20px); /* Ajusta a posição vertical dos botões */
    left: -10px; /* Ajusta a posição do botão anterior */
    right: -10px; /* Ajusta a posição do botão próximo */
  }

  .custom_next_arrow span, .custom_prev_arrow span {
    font-size: 1.5em;
  }

  .about_content h2 {
    font-size: 2em;
  }

  .about_content p {
    font-size: 1em;
  }
}

/* Responsivo para Celulares (telas menores) */
@media (max-width: 480px) {
  .about_image {
    max-height: 250px;
  }

  .slick-prev, .slick-next {
    width: 25px;
    height: 25px;
    top: calc(50% - 10px); /* Centraliza os botões verticalmente */
    left: -8px; /* Reposiciona o botão anterior */
    right: -8px; /* Reposiciona o botão próximo */
  }

  .custom_next_arrow span, .custom_prev_arrow span {
    font-size: 1.2em;
  }

  .about_content h2 {
    font-size: 1.8em;
  }

  .about_content p {
    font-size: 0.9em;
  }
}
