/* partners.module.css */
.stats_section {
  position: relative;
  width: 100%;
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4em 1em;
  margin-bottom: 100px;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(137, 31, 42, 0.7);
  z-index: 1;
}

.stats_container {
  position: relative;
  z-index: 2;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column; /* Alinha os itens verticalmente */
  justify-content: center;
  align-items: center;
  max-width: 1000px;
  gap: 20px;
  color: #fff;
  text-align: center;
}

.item {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-size: 20px;
}

.title_wrapper {
  margin-bottom: 20px;
}

.title_underline {
  width: 550px; /* Define a largura da linha */
  height: 2px;  /* Define a altura da linha */
  background-color: #fff; /* Cor da linha */
  border: none; /* Remove qualquer borda padrão */
  margin: 0.2em auto; /* Centraliza a linha e adiciona margem */
}

.slider_container {
  width: 100%; /* Define a largura do contêiner */
  overflow: hidden; /* Esconde as imagens que ultrapassam o contêiner */
  padding: 20px; /* Espaçamento interno */
}

.partner_image {
  width: calc(100% - 20px); /* Subtrai a margem para que as imagens se ajustem corretamente */
  height: auto; /* Mantém a proporção da imagem */
  margin: 0 10px; /* Adiciona espaçamento entre as imagens */
}

@media (max-width: 768px) { 
  
  .stats_section {
    position: relative;
    width: 100%;
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2em 1em; /* Reduzi o padding para mobile */
    margin-bottom: 50px; /* Reduzi a margem inferior */
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(137, 31, 42, 0.8); /* Aumentei a opacidade para melhorar a legibilidade */
    z-index: 1;
  }

  .stats_container {
    position: relative;
    z-index: 2;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 100%; /* Para garantir que ocupe toda a largura da tela */
    gap: 15px; /* Reduzi o gap entre os itens */
    color: #fff;
    text-align: center;
    padding: 0 1em; /* Adicionei padding lateral para espaçamento */
  }

  .item {
    font-size: 18px; /* Reduzi o tamanho da fonte para mobile */
    padding: 0.5em 0; /* Adicionei padding para melhor espaçamento interno */
  }

  .title_wrapper {
    margin-bottom: 15px; /* Reduzi a margem inferior */
  }

  .title_underline {
    width: 80%; /* Usei porcentagem para tornar a linha responsiva */
    height: 2px;
    background-color: #fff;
    margin: 0.2em auto;
  }

  .slider_container {
    width: 100%;
    overflow: hidden;
    padding: 10px; /* Reduzi o padding para mobile */
  }

  .partner_image {
    width: calc(100% - 3.5px);
    height: auto;
    margin: 0 5px; /* Reduzi a margem para mobile */
  }
}

@media (max-width: 1024px) {
  .stats_section {
    position: relative;
    width: 100%;
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2em 1em; /* Reduzi o padding para mobile */
    margin-bottom: 50px; /* Reduzi a margem inferior */
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(137, 31, 42, 0.8); /* Aumentei a opacidade para melhorar a legibilidade */
    z-index: 1;
  }

  .stats_container {
    position: relative;
    z-index: 2;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 100%; /* Para garantir que ocupe toda a largura da tela */
    gap: 15px; /* Reduzi o gap entre os itens */
    color: #fff;
    text-align: center;
    padding: 0 1em; /* Adicionei padding lateral para espaçamento */
  }

  .item {
    font-size: 18px; /* Reduzi o tamanho da fonte para mobile */
    padding: 0.5em 0; /* Adicionei padding para melhor espaçamento interno */
  }

  .title_wrapper {
    margin-bottom: 15px; /* Reduzi a margem inferior */
  }

  .title_underline {
    width: 80%; /* Usei porcentagem para tornar a linha responsiva */
    height: 2px;
    background-color: #fff;
    margin: 0.2em auto;
  }

  .slider_container {
    width: 100%;
    overflow: hidden;
    padding: 10px; /* Reduzi o padding para mobile */
  }

  .partner_image {
    width: calc(100% - 3.5px);
    height: auto;
    margin: 0 5px; /* Reduzi a margem para mobile */
  }
}
