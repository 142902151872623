.carouselContainer {
  text-align: center;
  margin: 0 auto;
  padding: 1em 2em;
}

.slider {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

.member {
  padding: 20px;
}

.memberCard {
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
  text-align: center;
  transition: transform 0.3s ease;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.memberCard:hover {
  transform: scale(1.05);
}

.memberImageContainer {
  position: relative;
  margin-bottom: 15px;
}

.memberImage {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  overflow: hidden;
  margin: 0 auto;
  background-color: #f0f0f0;
}

.memberImage img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.memberInfo {
  margin-top: 10px;
}

.memberName {
  font-size: 1.2em;
  font-weight: bold;
  color: #333;
}

.memberRole {
  font-size: 1em;
  color: #777;
  margin-bottom: 15px;
}

.socialIcons {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.socialIcon {
  color: #891F2A;
  font-size: 1.5em;
  transition: color 0.3s ease;
}

.socialIcon:hover {
  color: #b32d40;
}

.socialIconUnavailable {
  color: #ccc;
  font-size: 1.5em;
  cursor: not-allowed;
}

/* Ajustes para setas do slider */
.nextArrow,
.prevArrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: transparent;
  color: #891F2A;
  cursor: pointer;
  z-index: 1;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.slick-prev:before, 
.slick-next:before {
  display: none;
}

@media (max-width: 1024px) {
  .slider {
    max-width: 800px;
  }

  .memberCard {
    padding: 15px;
  }

  .memberImage {
    width: 100px;
    height: 100px;
  }

  .memberName {
    font-size: 1.1em;
  }

  .memberRole {
    font-size: 0.95em;
  }

  .socialIcon {
    font-size: 1.2em;
  }
}

@media (max-width: 768px) {
  .slider {
    max-width: 600px;
  }

  .memberCard {
    padding: 15px;
  }

  .memberImage {
    width: 90px;
    height: 90px;
  }

  .memberName {
    font-size: 1.1em;
  }

  .memberRole {
    font-size: 0.95em;
  }

  .socialIcon {
    font-size: 1.2em;
  }
}

@media (max-width: 480px) {
  .slider {
    max-width: 300px;
  }

  .memberCard {
    padding: 10px;
  }

  .memberImage {
    width: 80px;
    height: 80px;
  }

  .memberName {
    font-size: 1em;
  }

  .memberRole {
    font-size: 0.9em;
  }

  .socialIcon {
    font-size: 1em;
  }
}

.socialIcons {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.socialIcon,
.socialIconUnavailable {
  font-size: 1.5em;  /* Mantém o tamanho consistente */
  transition: color 0.3s ease;
}

.socialIcon {
  color: #891F2A;
}

.socialIcon:hover {
  color: #b32d40;
}

.socialIconUnavailable {
  color: #ccc;
  cursor: not-allowed;
}

/* Responsivo para telas menores */
@media (max-width: 1024px) {
  .socialIcon,
  .socialIconUnavailable {
    font-size: 1.2em;
  }
}

@media (max-width: 768px) {
  .socialIcon,
  .socialIconUnavailable {
    font-size: 1.2em;
  }
}

@media (max-width: 480px) {
  .socialIcon,
  .socialIconUnavailable {
    font-size: 1em;
  }
}
