/* Container principal da barra de navegação */
.headerContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 2rem;
  max-width: 1600px;
  margin: 0 auto;
  width: 100%;
}

/* Navbar padrão para Desktop */
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  padding: 1rem;
  padding-inline: 3em;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.08);
  position: relative;
  z-index: 1000;
}

.logo {
  width: 150px;
  height: auto;
  transition: width 0.3s ease;
}

/* Estilo da lista de navegação */
.list {
  display: flex;
  list-style: none;
  align-items: center;
}

.item {
  margin-right: 1em;
  padding: 0.2em;
  position: relative;
}

.item a {
  color: #000;
  text-decoration: none;
  padding: 0.5em;
  transition: color 0.3s ease, background-color 0.3s ease;
}

.item a:hover,
.item a:focus,
.activeLink a {
  color: #891F2A;
  border-radius: 2.5px;
}

.item a::after {
  content: '';
  position: absolute;
  bottom: -3px;
  left: 50%;
  width: 0;
  height: 2px;
  background-color: #891F2A;
  transition: width 0.3s ease, left 0.3s ease;
}

.item a:hover::after,
.item a:focus::after,
.activeLink a::after {
  width: 100%;
  left: 0;
}

/* Botão de orçamento */
.budget a {
  color: #fff;
  background-color: #891F2A;
  border: 2px solid #891F2A;
  padding: 0.5em 1em;
  border-radius: 10px;
  transition: background-color 0.3s ease, color 0.3s ease, border 0.3s ease;
}

.budget a::after {
  content: none;
}

.budget a:hover,
.budget a:focus,
.budget.activeLink a {
  color: #891F2A;
  background-color: #ffffff;
  border: 2px solid #891F2A;
  padding: 0.5em 1em;
  border-radius: 10px;
}

/* Estilos para o menu hamburguer */
.hamburger {
  display: none;
  flex-direction: column;
  cursor: pointer;
  z-index: 1100;
}

.hamburger div {
  width: 30px;
  height: 3px;
  background-color: #000;
  margin: 5px 0;
  transition: 0.4s;
}

.list {
  transition: max-height 0.4s ease, opacity 0.4s ease;
}

/* Animação do menu hamburguer quando ativado */
.hamburger.toggle .bar1 {
  transform: rotate(-45deg) translate(-5px, 6px);
}

.hamburger.toggle .bar2 {
  opacity: 0;
}

.hamburger.toggle .bar3 {
  transform: rotate(45deg) translate(-5px, -6px);
}

/* Responsivo para Tablets (768px - 1024px) */
@media (max-width: 1024px) {
  .navbar {
    padding-inline: 2em;
  }

  .item a::after {
    bottom: -2px;
  }
}

/* Responsivo para Celulares (320px - 767px) */
@media (max-width: 767px) {
  .navbar {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-inline: 1em;
    width: 100%;
  }

  .logo {
    width: 120px;
    height: auto;
    transition: width 0.3s ease;
    margin-left: 0;
  }

  /* Menu hamburguer visível em celulares */
  .hamburger {
    display: flex;
    align-self: center;
    margin-right: 0;
  }

  .hamburger div {
    width: 25px;
    height: 3px;
  }

  /* Lista de navegação escondida no mobile, até que seja ativada */
  .list {
    position: absolute;
    top: 100%;
    right: 0;
    left: 0;
    background-color: #fff;
    width: 100%;
    max-height: 0;
    opacity: 0;
    overflow: hidden;
    flex-direction: column;
    align-items: flex-start;
    background-color: rgba(255, 255, 255, 0.9);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
  }

  /* Quando o menu está ativo */
  .list.active {
    max-height: 400px;
    opacity: 1;
    padding: 1em 0;
  }

  .item {
    margin-right: 0;
    width: 100%;
  }

  .item a {
    padding: 1em;
    width: 100%;
    display: block;
    text-align: left;
  }

  /* Animação minimalista dos links dentro do menu hamburguer */
  .item a::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 2px;
    background-color: #891F2A;
    transform: scaleX(0);
    transition: transform 0.3s ease;
    transform-origin: right;
  }

  .item a:hover::after {
    transform: scaleX(1);
    transform-origin: left;
  }

  .budget a {
    padding: 1em;
    width: 100%;
    text-align: center;
  }

  .budget a::after {
    content: none;
  }

  /* Ajuste do logo quando o menu está ativo */
  .navbar.active .logo {
    margin-left: 1em;
    margin-top: 0.5em;
  }
}
