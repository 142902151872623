.container {
  text-align: center;
  margin: 0 auto;
  padding: 0em 2em 3em;
}

.title {
  text-align: center;
  font-size: 2em;
  font-weight: bold;
  color: #444; /* Cor mais suave */
  margin-bottom: 0.5em;
  line-height: 1.2; /* Melhor espaçamento entre linhas */
}

.title_underline {
  width: 60%;
  max-width: 500px;
  height: 3px;
  background-color: #891F2A;
  margin: 0.5em auto 1.5em auto; /* Melhor espaçamento */
}

.subtitle {
  font-size: 1.2em;
  margin-bottom: 1.5em;
  color: #666; /* Cor de texto secundária */
  line-height: 1.5; /* Melhor legibilidade */
  font-weight: 300; /* Texto mais leve */
}

.tabs {
  display: flex;
  justify-content: center;
  flex-wrap: wrap; /* Permite que as abas se ajustem em telas menores */
  margin-bottom: 2em;
}

.tabButton {
  padding: 0.5em 1.5em;
  margin: 0.5em;
  border: none;
  background-color: #f0f0f0;
  cursor: pointer;
  font-size: 1em;
  color: #444;
  border-radius: 4px; /* Bordas arredondadas para elegância */
  transition: background-color 0.3s ease, color 0.3s ease;
}

.tabButton:hover {
  background-color: #d1d1d1;
  color: #333;
}

.tabButton.active {
  background-color: #891F2A;
  color: #fff; /* Cor de texto branca para contraste */
}

.member {
  text-align: center;
  padding: 1.5em; 
}

.memberName {
  margin-top: 0.7em;
  font-size: 1.2em;
  color: #333;
  font-weight: 600; /* Um pouco mais grosso para ênfase */
}

.memberRole {
  font-size: 1em;
  color: #777; /* Cor mais clara para o subtítulo */
  margin-top: 0.3em;
  line-height: 1.4;
}

/* Adicionando responsividade */

@media (max-width: 1200px) {
  .title {
    font-size: 1.8em;
  }

  .subtitle {
    font-size: 1.1em;
  }

  .memberName {
    font-size: 1.1em;
  }

  .memberRole {
    font-size: 0.95em;
  }
}

@media (max-width: 768px) {
  .title {
    font-size: 1.6em;
  }

  .subtitle {
    font-size: 1em;
  }

  .memberName {
    font-size: 1em;
  }

  .memberRole {
    font-size: 0.9em;
  }
}

@media (max-width: 480px) {
  .title {
    font-size: 1.4em;
  }

  .subtitle {
    font-size: 0.9em;
  }

  .tabButton {
    font-size: 0.9em;
    padding: 0.4em 1em;
  }

  .memberName {
    font-size: 0.9em;
  }

  .memberRole {
    font-size: 0.85em;
  }
}
