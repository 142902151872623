.footer {
  background-color: #891F2A;
  color: #ffffff;
  padding: 1em;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.logo {
  font-size: 2rem;
  font-weight: bold;
  color: #fff;
  text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.4);
  margin-bottom: 0em;
  transition: transform 0.3s ease;
}

.contactButton {
  background: linear-gradient(135deg, #fff, #ccc); /* Gradiente para botão */
  color: #891F2A;
  padding: 0em 0em;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  font-size: 0.9em;
  transition: all 0.3s ease;
}

.contactButton:hover {
  background: linear-gradient(135deg, #ddd, #bbb);
  color: #fff;
}

.socialList a {
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5em;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.2);
  transition: transform 0.3s ease, background-color 0.3s ease;
}

.socialList a:hover {
  transform: scale(1.2) rotate(10deg);
  background-color: rgba(255, 255, 255, 0.4);
}

.copyRight {
  margin-top: 1.5em;
  font-size: 0.8rem;
  color: #ddd;
  transition: color 0.3s ease;
}

.copyRight span:hover {
  color: #ffffff;
}
