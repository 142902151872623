.card {
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  transition: 0.3s;
  width: 100%;
  border: 2px solid #891F2A;
  margin: 10px;
  height: 100%;
}

.card:hover {
  box-shadow: 0 8px 16px 0 rgba(31, 29, 29, 0.2);
}

.imgContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 150px; 
  width: 100%;
}

.card img {
  max-height: 70%;
  max-width: 70%;
}

.card_text {
  background-color: #891F2A;
  height: 150px;
  width: 208px; 
  color: white;
  padding: 20px 15px;
  text-align: center;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: baseline;
  
} 

.card_text h5 {
  font-size: 1.20em;
  margin-bottom: 0.5em;
}

.card_text p {
  font-size: 0.85em;
  margin: 0;
}

@media (max-width: 1024px) {
  .card_text {
    background-color: #891F2A;
    height: 150px;
    width: 228px; 
    color: white;
    padding: 20px 15px;
    text-align: center;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: baseline;
    
  } 
}