/* Estilos gerais da seção de Depoimentos */
.testimonials_section {
  padding: 2em 1em;
  background-color: #fff;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  margin-bottom: 2em;
  max-width: 1200px;
  border-radius: 15px;
}

.testimonials_section h2 {
  font-size: 2em;
  color: #333;
  margin-bottom: 0.5rem;
  font-weight: 600;
  position: relative;
}

.testimonials_section p {
  margin-bottom: 2em;
  font-size: 1.2em;
  color: #666;
}

.title_underline {
  width: 90%;
  max-width: 500px;
  height: 4px;
  background-color: #891F2A;
  margin: 0.5em auto 1.5em auto;
}

.slider {
  width: 100%;
  max-width: 1100px;
  margin: 0 auto;
}

.slick-slide {
  display: flex;
  justify-content: center;
  padding: 0 15px;
}

.testimonial {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 20px;
  text-align: left;
  width: 100%;
  max-width: 320px;
  margin: 10px auto;
  height: 420px; /* Tamanho fixo para garantir que todos os cards tenham o mesmo tamanho */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: transform 0.3s ease;
}

.testimonial:hover {
  transform: scale(1.01);
}

.testimonial h3 {
  font-size: 1.6em;
  color: #891F2A;
  margin-bottom: 0.5rem;
  font-weight: 600;
}

.testimonial h4 {
  font-size: 1.1em;
  color: #555;
  margin-bottom: 1rem;
}

.testimonial p {
  font-size: 1em;
  color: #666;
  margin-bottom: 1em;
  flex-grow: 1;
}

.nps {
  display: flex;
  justify-content: flex-start; /* Alinha o NPS e o "Leia mais" à esquerda */
  align-items: center;
  margin-top: 10px;
  position: relative;
  width: 100%;
  padding-top: 10px;
  border-top: 1px solid #eee; /* Linha sobre o NPS e o "Leia mais" */
}

.nps span {
  font-size: 0.9em;
  font-weight: bold;
  background-color: #891F2A;
  color: #fff;
  padding: 5px 10px;
  border-radius: 15px;
}

.nps a {
  color: #fff;
  background-color: #891F2A;
  padding: 5px 10px;
  border-radius: 15px;
  text-decoration: none;
  font-weight: bold;
  margin-left: 10px;
  white-space: nowrap;
}

.nps a:hover {
  background-color: #b32d40;
}

/* Responsividade */
@media (max-width: 1024px) {
  .slider {
    max-width: 800px;
  }

  .testimonial {
    max-width: 300px;
    height: 400px; /* Ajuste de altura para telas médias */
  }

  .testimonial p {
    font-size: 0.95em;
  }
}

@media (max-width: 768px) {
  .slider {
    max-width: 600px;
  }

  .testimonial {
    max-width: 280px;
    height: 390px; /* Ajuste de altura para tablets */
  }

  .testimonial h3 {
    font-size: 1.4em;
  }

  .testimonial h4 {
    font-size: 1em;
  }
}

@media (max-width: 480px) {
  .slider {
    max-width: 300px;
  }

  .testimonial {
    max-width: 100%;
    padding: 15px;
    height: auto; /* Altura automática para dispositivos móveis */
  }

  .testimonial h3 {
    font-size: 1.2em;
  }

  .testimonial h4 {
    font-size: 0.9em;
  }

  .testimonial p {
    font-size: 0.85em;
  }

  .nps span, .nps a {
    font-size: 0.8em;
    padding: 5px;
  }
}
